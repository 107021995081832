import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { app } from '@/config';
import { date } from '@/utils';

function FieldSaleDate(props) {
  const { fishing_date, fishing_day, fishing_month, fishing_year } = props;

  return (
    <div className="c__field-sale-date">
      <div className="w__date">
        <div className="w__field year">
          <Dropdown
            fluid
            selection
            options={app.fishingYear}
            placeholder={date.getFishingYear()}
            onChange={(event, field) =>
              fishing_year.input.onChange(field.value)
            }
          />
          <span>年</span>
        </div>

        <div className="w__field month">
          <Dropdown
            fluid
            selection
            options={app.fishingMonth}
            placeholder={date.getFishingMonth()}
            onChange={(event, field) =>
              fishing_month.input.onChange(field.value)
            }
          />
          <span>月</span>
        </div>

        <div className="w__field day">
          <Dropdown
            fluid
            selection
            options={app.fishingDay}
            placeholder={date.getFishingDay()}
            onChange={(event, field) => fishing_day.input.onChange(field.value)}
          />
          <span>日</span>
        </div>
      </div>
      {fishing_date.meta.error && (
        <a id="error">
          <div className="redux_form error">{fishing_date.meta.error}</div>
        </a>
      )}
    </div>
  );
}

export default FieldSaleDate;
